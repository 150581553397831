import React from "react";
import { graphql } from 'gatsby'
import CaseStudy from "../components/case-study/caseStudy";
import { HelmetDatoCms } from "gatsby-source-datocms";
import { resetWebflow, sortRecords } from "../utils/common"
import Notification from "../components/header/notification";
import ReactPaginate from 'react-paginate';
import { Pagination } from "../utils/pagination";
import Layout from "../layouts";

const ITEM_PER_PAGE = 14;
export default class CaseStudyPage extends React.Component {
  constructor(props) {
    super(props);
    let {
      data: {
        allDatoCmsCaseStudyDetail: { edges: items }
      }
    } = props;

    sortRecords(items);
    let pagination = new Pagination(items, ITEM_PER_PAGE);
    this.state = {
      allItems: items,
      pagination,
      data: pagination.pageChanged()
    }
  }

  handlePageClick = data => {
    let page = data.selected + 1;
    this.setState({
      data: this.state.pagination.pageChanged(page)
    })
  };

  onCategoryClick = category => {        
    let filteredItems = this.state.allItems.filter(({node: item}) => {      
      return item.category && item.category.some(itemCat => itemCat.id === category.id)
    });
    let newPaging = new Pagination(filteredItems, ITEM_PER_PAGE);
    this.setState({
      pagination: newPaging,
      data: newPaging.pageChanged()
    })
  }

  loadAll = () => {
    let items = this.state.allItems.slice();
    let newPaging = new Pagination(items, ITEM_PER_PAGE);
    this.setState({
      pagination: newPaging,
      data: newPaging.pageChanged()
    })
  }

  render() {
    let {
      data: {
        datoCmsCaseStudy: { title, subtitle, seoMetaTags, notificationPage },
        allDatoCmsNotification: { edges: notifications },
        allDatoCmsCaseStudyCategory: { edges: categories }
      }
    } = this.props;
    resetWebflow("5aaf9892ca4ae1416748d87d");

    return (
      <Layout>
        <Notification notifications={notifications} linkedPage={notificationPage}></Notification>
        <HelmetDatoCms seo={seoMetaTags} />
        <header className="main-header about reducedbottom">
          <div className="container grid">
            <h1 className="h-nofixedwidth">{title}</h1>
            <div className="sm-spacing" />
            <div className="p center-align">{subtitle}</div>
            <div className="md-spacing" />

            <div className="w-tabs block-center">
              <div className="tabs-side-menu w-clearfix w-tab-menu">
                <a
                  onClick={this.loadAll}
                  data-w-tab="All"
                  className="tabs-menu side-menu w-inline-block w-tab-link w--current"
                >
                  <div>All</div>
                </a>
                {categories.map(({ node }) => (
                  <a
                    key={node.id}
                    data-w-tab={node.title}
                    className="tabs-menu side-menu w-inline-block w-tab-link"
                    onClick={this.onCategoryClick.bind(this, node)}
                  >
                    <div>{node.title}</div>
                  </a>
                ))}
              </div>
            </div>

            <div className="container grid">
              {this.state.data.map(({ node: detail }) => <CaseStudy data={detail} ></CaseStudy>)} 
            </div>

            {this.state.pagination.isShowPagination &&
              <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                pageClassName={'page-number'}
                breakClassName={'page-number'}
                breakLabel={'...'}
                pageCount={this.state.pagination.pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={this.handlePageClick}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
              />
            }
          </div>
        </header>
      </Layout>
    );
  }
};

export const query = graphql`
  query CaseStudyQuery {
    datoCmsCaseStudy {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      title
      subtitle
      notificationPage {
        id
        page
      }          
    }
    allDatoCmsCaseStudyDetail {
      edges {
        node {
          id
          slug  
          pageTitle
          publishedOn(formatString: "MMM D, YYYY H:mm A")
          featuredImage {
            url
            alt
          }
          content
          subTitle
          position
          category {
            id
            title
          }
        }
      }
    }
    allDatoCmsCaseStudyCategory {
      edges {
        node {
          id
          title
        }
      }
    }
    allDatoCmsNotification {
      edges {
        node {
          id
          content
          show
          weeks
          notificationPosition
          linkToPage {
            id
            page
          }
          backgroundColor {
            hex
          }
        }
      }
    }
  }
`;
