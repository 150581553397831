import React from "react";
import ReactMarkdown from "react-markdown";
import { Link } from "gatsby";
import { resizeAndConvertImage } from "../../utils/common";

export default ({ data }) => {
    return (
        <React.Fragment>
            <div className="_2cols-box-extended case-study">
                <Link to={`/case-study/${data.slug}`} className="link-container">
                    {data.featuredImage && <img src={`${data.featuredImage.url}${resizeAndConvertImage(1000)}`} width="500" alt={data.featuredImage.alt} />}
                    <div className="sm-spacing" />
                    <h4 className="h4">{data.pageTitle}</h4>
                    <div className="sm-spacing" />
                    <p className="small-copy">
                        <ReactMarkdown source={data.subTitle} escapeHtml={false} />
                    </p>
                    <div className="sm-spacing" />                    
                </Link>
            </div>
        </React.Fragment>
    );
};